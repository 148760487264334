import React, { useState } from 'react';
import Help from '../static/images/help.svg';

const ExamplePrompts = () => {
    const [isOpen, setIsOpen] = useState(false);

    const togglePanel = () => {
        setIsOpen(!isOpen);
    };

    const closePanel = () => {
        setIsOpen(false);
    };

    return (
        <div>
            <button className='example-prompt-btn' onClick={togglePanel}>
                <img src={Help} alt='Help' />
                Help/FAQ
            </button>
            {isOpen && (
                <div className="side-panel">
                    <button onClick={closePanel}>X Close</button>
                    <div className="scrollable-content">
                        <h2>Example Prompts</h2>
                        <ul>
                            <li>Summarize the National Disaster Plan for Dominica. Give me the top 5 points that communities should know.</li><br />
                            <li>What parts of Dominica's National Disaster Plan talks about community response/ NGOs or what can they do to best utilize the National Disaster Plan</li> <br />
                            <li>Can you write a letter from the perspective of a vulnerable community in Dominica wanting to bring attention to their community in relation to the National Disaster Plan?</li>
                        </ul>
                        <hr />
                        <h2>List of Documents</h2>
                            <table className='doc-table'>
                                <thead>
                                    <tr>
                                        <th>Document Name</th>
                                        <th>Region</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Comprehensive Disaster Management Policy</td>
                                        <td>Anguilla</td>
                                    </tr>
                                    <tr>
                                        <td>Disaster Management Act 2002</td>
                                        <td>Antigua and Barbuda</td>
                                    </tr>
                                    <tr>
                                        <td>Disaster Preparedness and Response</td>
                                        <td>Bahamas</td>
                                    </tr>
                                    <tr>
                                        <td>Disaster Preparedness and Response</td>
                                        <td>Bahamas</td>
                                    </tr>
                                    <tr>
                                        <td>Disaster Preparedness and Response Act</td>
                                        <td>Belize</td>
                                    </tr>
                                    <tr>
                                        <td>Disaster Management Policy Framework</td>
                                        <td>British Virgin Islands</td>
                                    </tr>
                                    <tr>
                                        <td>Disaster Preparedness and Hazard Management Law</td>
                                        <td>Cayman Islands</td>
                                    </tr>
                                    <tr>
                                        <td>National Disaster Plan</td>
                                        <td>Dominica</td>
                                    </tr>
                                    <tr>
                                        <td>Disaster Management Bill</td>
                                        <td>Grenada</td>
                                    </tr>
                                    <tr>
                                        <td>Disaster Risk Management Policy</td>
                                        <td>Guyana</td>
                                    </tr>
                                    <tr>
                                        <td>National Integrated Disaster Risk Management Plan and Implementation Strategy</td>
                                        <td>Guyana</td>
                                    </tr>
                                    <tr>
                                        <td>Disaster Risk Management Policy and Strategy</td>
                                        <td>Jamaica</td>
                                    </tr>
                                    <tr>
                                        <td>National Natural Disaster Risk Financing Policy</td>
                                        <td>Jamaica</td>
                                    </tr>
                                    <tr>
                                        <td>National Hazard Risk Reduction Policy</td>
                                        <td>Jamaica</td>
                                    </tr>
                                    <tr>
                                        <td>Disaster Risk Management Act</td>
                                        <td>Jamaica</td>
                                    </tr>
                                    <tr>
                                        <td>Disaster Preparedness and Response Act</td>
                                        <td>Montserrat</td>
                                    </tr>
                                    <tr>
                                        <td>Government of Montserrat Policy Agenda</td>
                                        <td>Montserrat</td>
                                    </tr>
                                    <tr>
                                        <td>NDMA National Disaster Plan</td>
                                        <td>St Kitts Nevis</td>
                                    </tr>
                                    <tr>
                                        <td>National Disaster Plan</td>
                                        <td>St Kitts Nevis</td>
                                    </tr>
                                    <tr>
                                        <td>Hurricane Action Plan</td>
                                        <td>St Kitts Nevis</td>
                                    </tr>
                                    <tr>
                                        <td>National Emergency Management System</td>
                                        <td>St Lucia</td>
                                    </tr>
                                    <tr>
                                        <td>Disaster Management Policy Framework</td>
                                        <td>St Lucia</td>
                                    </tr>
                                    <tr>
                                        <td>National Disaster Plan 2005</td>
                                        <td>St Vincent Grenadines</td>
                                    </tr>
                                    <tr>
                                        <td>Disaster Risk Reduction 2014</td>
                                        <td>Suriname</td>
                                    </tr>
                                    <tr>
                                        <td>Disaster Measures Act</td>
                                        <td>Trinidad and Tobago</td>
                                    </tr>
                                    <tr>
                                        <td>Municipal Corporations Act (1990)</td>
                                        <td>Trinidad and Tobago</td>
                                    </tr>
                                    <tr>
                                        <td>Environmental Management Act</td>
                                        <td>Trinidad and Tobago</td>
                                    </tr>
                                    <tr>
                                        <td>National Disaster Management Plan</td>
                                        <td>Turks and Caicos</td>
                                    </tr>
                                    <tr>
                                        <td>Disaster Relief Management Plan</td>
                                        <td>Turks and Caicos</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                </div>
            )}
        </div>
    );
};

export default ExamplePrompts;