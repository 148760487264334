import React from 'react';
import ChatInterface from './components/ChatInterface';
import FeedbackForm from './components/FeedbackForm.jsx';
import ExamplePrompts from './components/ExamplePrompts.jsx';

function App() {

  return (
    <div className="App">
        <ChatInterface />
        <FeedbackForm />
        <ExamplePrompts />
    </div>
  );
}

export default App;
