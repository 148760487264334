import React, { useState } from 'react';
import Feedback from '../static/images/feedback.svg';

const FeedbackForm = () => {
    const [showForm, setShowForm] = useState(false);

    const handleButtonClick = () => {
        setShowForm(prevShowForm => !prevShowForm);
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <button className="feedback" onClick={handleButtonClick}>
                <img src={Feedback} alt='Feedback' />
                {showForm ? 'Close Feedback' : 'Feedback'}
            </button>
            {showForm && (
                <>
                    <div className='overlay'></div>
                    <iframe className='feedback-frame'
                        src="https://docs.google.com/forms/d/e/1FAIpQLSfgCuyZxXA1OzgmdGAdpfqtB9Z7iP7EfdTTDs36qezDCCq2_g/viewform?embedded=true"
                        width="640"
                        height="820"
                        frameBorder="0"
                        marginHeight="0"
                        marginWidth="0"
                        style={{ display: 'block', margin: '0 auto' }}
                    >
                        Loading…
                    </iframe>
                </>
            )}
        </div>
    );
};

export default FeedbackForm;